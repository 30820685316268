import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MalaysiaDataService {
  malaysiaData = [
    {
        "state": "Johor",
        "district": [
            "Batu Pahat",
            "Johor Bahru",
            "Kluang",
            "Kota Tinggi",
            "Kulai",
            "Mersing",
            "Muar",
            "Pontian",
            "Segamat",
            "Tangkak"
        ]
    },
    {
        "state": "Kedah",
        "district": [
            "Baling",
            "Bandar Baharu",
            "Kota Setar",
            "Kuala Muda",
            "Kubang Pasu",
            "Kulim",
            "Langkawi",
            "Padang Terap",
            "Pendang",
            "Pokok Sena",
            "Sik",
            "Yan"
        ]
    },
    {
        "state": "Kelantan",
        "district": [
            "Jajahan Bachok",
            "Jajahan Gua Musang",
            "Jajahan Jeli",
            "Jajahan Kota Bharu",
            "Jajahan Kuala Krai",
            "Jajahan Machang",
            "Jajahan Pasir Mas",
            "Jajahan Pasir Puteh",
            "Jajahan Tanah Merah",
            "Jajahan Tumpat"
        ]
    },
    {
        "state": "Melaka",
        "district": [
            "Alor Gajah",
            "Jasin",
            "Melaka Tengah"
        ]
    },
    {
        "state": "Negeri Sembilan",
        "district": [
            "Jelebu",
            "Jempol",
            "Kuala Pilah",
            "Port Dickson",
            "Rembau",
            "Seremban",
            "Tampin"
        ]
    },
    {
        "state": "Pahang",
        "district": [
            "Bentong",
            "Bera",
            "Cameron Highlands",
            "Jerantut",
            "Kuantan",
            "Lipis",
            "Maran",
            "Pekan",
            "Raub",
            "Rompin",
            "Temerloh"
        ]
    },
    {
        "state": "Pulau Pinang",
        "district": [
            "Barat Daya",
            "Seberang Perai Selatan",
            "Seberang Perai Tengah",
            "Seberang Perai Utara",
            "Timur Laut"
        ]
    },
    {
        "state": "Perak",
        "district": [
            "Bagan Datuk",
            "Batang Padang",
            "Hilir Perak",
            "Hulu Perak",
            "Kampar",
            "Kerian",
            "Kinta",
            "Kuala Kangsar",
            "Larut Matang",
            "Manjung",
            "Muallim",
            "Perak Tengah",
            "Selama"
        ]
    },
    {
        "state": "Perlis",
        "district": [
            "Bandar Arau",
            "Bandar Kangar",
            "Mukim Arau",
            "Mukim Beseri",
            "Mukim Chuping",
            "Mukim Jejawi",
            "Mukim Kayang",
            "Mukim Kechor",
            "Mukim Kuala Perlis",
            "Mukim Kurong Anai",
            "Mukim Ngulang",
            "Mukim Oran",
            "Mukim Padang Pauh",
            "Mukim Padang Siding",
            "Mukim Paya",
            "Mukim Sanglang",
            "Mukim Sena",
            "Mukim Seriap",
            "Mukim Sungai Adam",
            "Mukim Titi Tinggi",
            "Mukim Utan Aji",
            "Mukim Wang Bintong",
            "Pekan Kaki Bukit",
            "Pekan Kuala Perlis",
            "Pekan Padang Besar"
        ]
    },
    {
        "state": "Selangor",
        "district": [
            "Gombak",
            "Ulu Langat",
            "Ulu Selangor",
            "Klang",
            "Kuala Langat",
            "Kuala Selangor",
            "Petaling",
            "Sabak Bernam",
            "Sepang"
        ]
    },
    {
        "state": "Terengganu",
        "district": [
            "Besut",
            "Dungun",
            "Hulu Terengganu",
            "Kemaman",
            "Kuala Nerus",
            "Kuala Terengganu",
            "Marang",
            "Setiu"
        ]
    },
    {
        "state": "Sabah",
        "district": [
            "Daerah Beaufort",
            "Daerah Keningau",
            "Daerah Kinabatangan",
            "Daerah Kota Belud",
            "Daerah Kota Kinabalu",
            "Daerah Kota Marudu",
            "Daerah Kuala Penyu",
            "Daerah Kudat",
            "Daerah Kunak",
            "Daerah Labuk Sugut",
            "Daerah Lahad Datu",
            "Daerah Papar",
            "Daerah Penampang",
            "Daerah Pensiangan",
            "Daerah Pitas",
            "Daerah Putatan",
            "Daerah Ranau",
            "Daerah Sandakan",
            "Daerah Semporna",
            "Daerah Sipitang",
            "Daerah Tambunan",
            "Daerah Tawau",
            "Daerah Tenom",
            "Daerah Tongod",
            "Daerah Tuaran"
        ]
    },
    {
        "state": "Sarawak",
        "district": [
            "Bahagian Betong",
            "Bahagian Bintulu",
            "Bahagian Kapit",
            "Bahagian Kuching",
            "Bahagian Limbang",
            "Bahagian Miri",
            "Bahagian Mukah",
            "Bahagian Samarahan",
            "Bahagian Sarikei",
            "Bahagian Serian",
            "Bahagian Sibu",
            "Bahagian Sri Aman"
        ]
    },
    { 
        "state": "W.P Kuala Lumpur",
        "district": [
            "Bandar Kuala Lumpur",
            "Bandar Petaling Jaya",
            "Mukim Ampang",
            "Mukim Batu",
            "Mukim Cheras",
            "Mukim Hulu Kelang",
            "Mukim Kuala Lumpur",
            "Mukim Petaling",
            "Mukim Setapak"
        ]
    },
    {
        "state": "W.P Putrajaya",
        "district": [
            "Presint 1",
            "Presint 5",
            "Presint 6",
            "Presint 7",
            "Presint 8",
            "Presint 9",
            "Presint 10",
            "Presint 11",
            "Presint 12",
            "Presint 14",
            "Presint 15",
            "Presint 16",
            "Presint 17",
            "Presint 18"
        ]
    },
    {
        "state": "W.P Labuan",
        "district": []
    }
]

  constructor() { }

  getMalaysiaData() {
    return this.malaysiaData;
  }
}

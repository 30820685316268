<div class="container-fluid">
  <div class="row">
    <div class="col-xl-7" [ngStyle]="{'background-image': 'url(assets/images/login/4.jpg)',
                                     'background-size': 'cover',
                                     'background-position': 'center center',
                                     'display': 'block'}">
      <img class="bg-img-cover bg-center d-none" src="assets/images/login/4.jpg" alt="looginpage">
    </div>
    <div class="col-xl-5 p-0">
      <div class="login-card">
        <div>

          <div class="login-main"> 

            <a class="logo text-center" href="#">
              <img class="img-fluid for-light" src="assets/images/logo-edssbr-png.png" width="50%" height="50%" alt="looginpage">
              <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage">
            
            </a>
            <div class="text-center">
              <h3>Admin MYRUMAHKIRA</h3>
              </div>
            <form class="theme-form" [formGroup]="loginForm">
              <h4>Welcome</h4>
              <p>Enter your email & password to login</p>
              <div class="form-group">
                <label class="col-form-label">Email Address</label>
                <input class="form-control"
                formControlName="email"
                [ngClass]="{ 'is-invalid': loginFormSubmitted && lf.email.invalid, 'is-valid': loginFormSubmitted && !lf.email.invalid }" type="email" required placeholder="example@yourmail.com">
                <div *ngIf="loginFormSubmitted && (lf.email.errors?.required )"
                  class="text text-danger mt-1">
                  Email is required
                </div>
                <div *ngIf="loginFormSubmitted && (lf.email.errors?.email )" class="text text-danger mt-1">
                  Invalid Email
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <input class="form-control" formControlName="password" [type]="show ? 'text' : 'password'" name="login[password]" required="" placeholder="*********">
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                <div *ngIf="loginFormSubmitted && (lf.password.errors?.required )" class="text text-danger mt-1">
                  Password is required
                </div>
              </div>
              <div class="form-group mb-0">
                <div class="checkbox p-0">
                  <input id="checkbox1" type="checkbox">
                  <label class="text-muted" for="checkbox1">Remember password</label>
                </div>
                <!-- <a [routerLink]="'/admin/forgot-password'" class="link">Forgot password?</a> -->
                <button class="btn btn-primary btn-block" type="submit" [disabled]=" isLoading"  (click)="login()">Sign in <i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading}"></i></button>
              </div>
              <!-- <h6 class="text-muted mt-4 or">Or Sign in with</h6>
              <div class="social mt-4">
                <div class="btn-showcase">
                  <a class="btn btn-light" href="https://www.linkedin.com/login" target="_blank">
                    <app-feather-icons [icon]="'linkedin'" class="txt-linkedin"></app-feather-icons> LinkedIn 
                  </a>
                  <a class="btn btn-light" href="https://twitter.com/login?lang=en" target="_blank">
                   <app-feather-icons [icon]="'twitter'" class="txt-twitter"></app-feather-icons>twitter
                  </a>
                  <a class="btn btn-light" href="https://www.facebook.com/" target="_blank">
                    <app-feather-icons [icon]="'facebook'" class="txt-fb"></app-feather-icons>facebook
                  </a>
                </div>
              </div>
              <p class="mt-4 mb-0">Don't have account?
                <a [routerLink]="'/authentication/register/simple'" class="ml-2">Create Account</a>
              </p> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
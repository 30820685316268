import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../services/firebase/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  constructor(
    public authService: AuthService,
    private cookieService: CookieService,
    private router: Router,
  ) { 
    this.name = this.cookieService.get('name');
    this.role = this.cookieService.get('role');
  }

  name:any;
  role:any;



  ngOnInit(
    
  ) {
  }

  logout(){

    this.cookieService.delete('token', '/');
    this.cookieService.delete('user', '/');
    this.cookieService.delete('role', '/');
    this.cookieService.delete('name', '/');
    this.router.navigateByUrl('admin/login');

  }

}

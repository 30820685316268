import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root'
})
export class ApiRestServiceService {

  uri = environment.api_url;

  private tokenValue: String;

  tokenData = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImdtIiwic3ViIjoxLCJpYXQiOjE3MDcyNDAyMzR9.sqsq7CVn2yATd7La8NCXUmFYCiFl2gQ2z-xB_RFvhHY';

  httpOptions = {
    headers: new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': 'Bearer Token',
      // 'Access-Control-Allow-Origin' : 'true',
      // 'Access-Control-Allow-Methods': 'POST, PUT, DELETE, GET, OPTIONS',
      // 'Access-Control-Allow-Credentials' : 'true',
      // 'Access-Control-Allow-Headers': 'Content-Type, Content-Length, Accept-Encoding, Authorization, X-Requested-With'
    })
  };

  constructor(
    private http: HttpClient,

    private cookieService: CookieService
  ) {
    
   }

  //Login API

  login(data){
    return this.http.post(`${this.uri}admin/login`,data);
  }


  //Dashboard
   dataDashboard(token){

   

    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + token);

    return this.http.get(`${this.uri}dashboard/dataDashboard`, this.httpOptions);


  }

  dataDashboardHouseIncome(token){

   

    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + token);

    return this.http.get(`${this.uri}dashboard/dataHouseholdIncome`, this.httpOptions);


  }

  dataDashboardTotalAllState(token){

   

    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + token);

    return this.http.get(`${this.uri}dashboard/dataTotalAllState`, this.httpOptions);


  }


  

  dataDashboardPortFolioByMonthYear(token,data){

   

    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + token);

    return this.http.post(`${this.uri}dashboard/dataTotalPortFolioByMonthYear`,data, this.httpOptions);


  }

  dataDashboardUserByMonthYear(token,data){

   

    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + token);

    return this.http.post(`${this.uri}dashboard/dataTotalUserbyMonthYear`,data, this.httpOptions);


  }

  dataDashboardRangeSalaryTotalUser(token){

   

    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + token);

    return this.http.get(`${this.uri}dashboard/dataRangeSalaryTotalUser`, this.httpOptions);


  }

  dataDashboardHousePriceByUser(token){

   

    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + token);

    return this.http.get(`${this.uri}dashboard/dataTotalHousePriceByUser`, this.httpOptions);

  }
  

  
  listUsers(token) {

    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + token);

  
    
    return this.http.get(`${this.uri}user/all`, this.httpOptions);

  }

  usersDetails(data, token) {

    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + token);
    
    return this.http.post(`${this.uri}user/byid`,data, this.httpOptions);

  }

  listPortfolio(token) {

    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + token);
    
    return this.http.get(`${this.uri}portfolio/all`, this.httpOptions);

  }

  listPortfolioDetails(data,token) {

    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + token);

    if(data.role === "USER"){
      return this.http.post(`${this.uri}portfolio/byid`,data, this.httpOptions);
    }

    if(data.role === "GUEST"){
      return this.http.post(`${this.uri}portfolio/guest/byid`,data, this.httpOptions);
    }


    
  

  }

  listBankRates(token){

    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + token);
    
    return this.http.get(`${this.uri}bank-rate/all`, this.httpOptions);

  }

  detailsBankRates(data,token){

    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + token);
    
    return this.http.post(`${this.uri}bank-rate/byid`,data, this.httpOptions);
    
  }

  postBankRates(data,token){

    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + token);
    
    return this.http.post(`${this.uri}bank-rate/create`,data, this.httpOptions);
    
  }

  updateBankRates(data,token){

    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + token);
    
    return this.http.patch(`${this.uri}bank-rate/update`,data, this.httpOptions);
    
  }

  
  deleteBankRates(data,token){

   let options = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': 'Bearer Token',
        // 'Access-Control-Allow-Origin' : 'true',
        // 'Access-Control-Allow-Methods': 'POST, PUT, DELETE, GET, OPTIONS',
        // 'Access-Control-Allow-Credentials' : 'true',
        // 'Access-Control-Allow-Headers': 'Content-Type, Content-Length, Accept-Encoding, Authorization, X-Requested-With'
      }),
      body : {
        id : data.id
      }
    };

    options.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + token);


    
    return this.http.delete(`${this.uri}bank-rate/delete`, options);
    
  }

  allPropertyData(token){

    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' +  token);

    
    return this.http.get(`${this.uri}property/all`, this.httpOptions);
  }




  //Dashboard
  // listPortfolioDetails(data) {

  //   this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + token);
   
  //   return this.http.post(`${this.uri}portfolio/byid`,data, this.httpOptions);

  // }

  listPortfolioByPage(data,token) {

    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + token);
    
    return this.http.post(`${this.uri}portfolio/byPage`,data, this.httpOptions);
    

  }

  listUsersByPage(data, token) {

    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + token);
    
    return this.http.post(`${this.uri}user/byPage`,data, this.httpOptions);

  }

  registerAdmin(data, token) {

    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + token);
    
    return this.http.post(`${this.uri}admin/create`,data, this.httpOptions);

  }

  listAdminByPage(data, token) {

    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + token);
    
    return this.http.post(`${this.uri}admin/byPage`,data, this.httpOptions);

  }

  dataMinMaxSalary(token){

    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' +  token);

    
    return this.http.get(`${this.uri}dashboard/dataMinMaxSalary`, this.httpOptions);

  }


  dataMinMaxSHousePricePurchase(token){

    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' +  token);

    
    return this.http.get(`${this.uri}dashboard/dataMinMaxHousePricePurchase`, this.httpOptions);
  }




}

<div class="media profile-media">
  <img class="b-r-10" src="assets/images/dashboard/avataaars3.png" width="45px" alt="">
  <div class="media-body">
    <span>{{name}}</span>
    <p class="mb-0 font-roboto">{{role}} <i class="middle fa fa-angle-down"></i></p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <!-- <li><a routerLink="admin/profile-account"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></a></li> -->

  <li><a href="#" (click)="logout()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Logout </span></a></li>
  <!-- <li><a href="#"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li> -->
  <!-- <li><a routerLink="admin/login"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Log out</span></a></li> -->
</ul>
import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../../shared/services/firebase/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import decode from 'jwt-decode';
import { ApiRestServiceService } from '../../service/api-rest-service.service';

import { environment } from '../../../environments/environment';

import Swal from 'sweetalert2';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public show: boolean = false;
  public loginForm: FormGroup;
  public errorMessage: any;
  isLoading = false;

  tokenData:any;

  loginFormSubmitted = false;

  
  secureFlag = environment.cookie_secure_flag;

  loginData:any;

  constructor(
    public authService: AuthService, 
    private fb: FormBuilder, 
    private router: Router,
    private cdr: ChangeDetectorRef,
    private serviceAPI: ApiRestServiceService,
    private cookieService: CookieService) {
      this.loginForm = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required]
      });
  }

  ngOnInit() {

    
    if (this.cookieService.get('token') !== '') {

      this.tokenData = decode(this.cookieService.get('token'));

      console.log(this.tokenData.id);

      if ((this.tokenData.role === 2 || this.tokenData.role === 3) || this.tokenData.role) {

        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';

        // Swal.close()
        this.router.navigateByUrl('admin/dashboard');

        // this.router.navigate(['home']);

      } 
      else {

        this.cookieService.delete('token', '/');
        this.cookieService.delete('user', '/');
      }

    }
  }

  get lf() {
    return this.loginForm.controls;
  }

  showPassword() {
    this.show = !this.show;
  }
  
  // Login With Google
  loginGoogle() {
    this.authService.GoogleAuth();
  }

  // Login With Twitter
  loginTwitter(): void {
    this.authService.signInTwitter();
  }

  // Login With Facebook
  loginFacebook() {
    this.authService.signInFacebok();
  }

  // Simple Login
  login() {
    // this.authService.SignIn(this.loginForm.value['email'], this.loginForm.value['password']);
    //navigation link.
     // this.router.navigate(['dashboard/default']);

     this.loginFormSubmitted = true;
     if (this.loginForm.invalid) {
 
      console.log("this.loginForm.invalid: ", this.loginForm.invalid);
      console.log("this.loginForm: ", this.loginForm);
      
      this.isLoading = false;
       return;
     } else if (this.loginForm.valid) {
 
       const LoginData = {
 
         "id": this.loginForm.get('email').value,
 
         "password": this.loginForm.get('password').value,
 
       };
 
      //  this.spinner.show(undefined,
      //    {
      //      type: 'ball-triangle-path',
      //      size: 'medium',
      //      bdColor: 'rgba(0, 0, 0, 0.8)',
      //      color: '#fff',
      //      fullScreen: true
      //    });
       
      this.isLoading = true;
 
 
       console.log("LoginData: ", LoginData)
 
       this.userSignIn(LoginData);
 
     }
  }

  userSignIn(data) {
    try {
      this.serviceAPI.login(data)
        .subscribe(async (res) => {
          this.loginData = res;
          console.log('this.loginData : ', this.loginData);
          if(this.loginData.success == true){
          console.log("success");
          
  
          this.tokenData = decode(this.loginData.result.access_token);
          console.log('tokenData : ', this.tokenData);
          this.isLoading = false;

          this.cookieService.set('token', this.loginData.result.access_token, 0, '/', '', this.secureFlag, 'Strict');
          this.cookieService.set('user', this.loginData.result.id, 0, '/', '', this.secureFlag, 'Strict');
          this.cookieService.set('name', this.tokenData.name, 0, '/', '', this.secureFlag, 'Strict');
          this.cookieService.set('role', this.tokenData.role == 3 ? 'Super Admin' : this.tokenData.role == 2 ? 'Admin' : 'Admin', 0, '/', '', this.secureFlag, 'Strict');

          if( this.tokenData.role == 2 || this.tokenData.role == 3  ) {

            this.router.navigate(['admin/dashboard'])


         }

        }else if(this.loginData.success == false){

          await Swal.fire({
            position: 'center',
            icon: 'error', // Change the icon to 'error'
            title: 'Sorry', // Customize the error title
            text: 'Your email not valid or password are wrong. Please try again.', // Customize the error message
            showConfirmButton: true, // Show the confirm button
            allowOutsideClick: false
          });

          this.isLoading = false;
          console.log("false");
        }


       
          // await Swal.fire({
          //   position: 'center',
          //   icon: 'success',
          //   title: this.translate.instant("swal.login.success-title"),
          //   text: this.translate.instant("swal.login.success-text"),
          //   showConfirmButton: false,
          //   timer: 3000,
          //   allowOutsideClick: false
          // });


        }, async (error: any) => {

          console.log(error);

          await Swal.fire({
            position: 'center',
            icon: 'error', // Change the icon to 'error'
            title: 'Sorry', // Customize the error title
            text: 'Your email not valid or password are wrong. Please try again.', // Customize the error message
            showConfirmButton: true, // Show the confirm button
            allowOutsideClick: false
          });

          this.isLoading = false;
          this.cdr.markForCheck();



        });
    } catch (error) {
      console.log("userSignIn error: ", error);

    }

  }


}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class houseTypeService {

  propertyType = [
    "terrace",
    "single_storey",
    "double_storey",
    "detached",
    "town_house",
    "cluster",
    "flat",
    "condominium",
    "others"
  ];

  constructor(private translate: TranslateService) { }

  getPropertyTypeDataTranslate() {

    this.translate.use('my');

    return this.propertyType.map(type => this.translate.instant(type));
  }

  
  getPropertyTypeData() {

    this.translate.use('my');
    
    return this.propertyType.map(type => this.translate.instant(type));
  }
}

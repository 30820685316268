import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/firebase/auth.service';
import { CookieService } from 'ngx-cookie-service';
import decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  TokenPayload:any;
  token:any;
  userId:any;
  
  constructor(
    public authService: AuthService,
    private cookieService: CookieService,
    public router: Router) { }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {

   
    // Guard for user is login or not
   // let user = JSON.parse(localStorage.getItem('user'));

    this.token = this.cookieService.get('token');
    this.userId = this.cookieService.get('user');

    if (this.token !== '') {

      this.TokenPayload = decode(this.cookieService.get('token'));

   

      if(this.TokenPayload.id === this.userId){

        console.log('this.TokenPayload: ', this.TokenPayload);

      ///  this.router.navigate(['/admin/dashboard']);
         return true

      }else{

        console.log('Page is not accessible!');
        // redirect to 403 page

       // this.router.navigate(['unauthorized']);
       return true;
      }
    
    }
    else  {

      console.log('No token!');
      this.cookieService.delete('user', '/');
      this.cookieService.delete('token', '/');

      // if (!Object.keys(user).length) {
      //   this.router.navigate(['/auth/login']);
      //   return true
      // }

      this.router.navigate(['admin/login']);
      return true
    }
    return true
  }
  
}
